import './App.css';
import {Table} from "./Table";

export default function App() {
  return (
    <div className="App">
      <Table />
    </div>
  );
}
